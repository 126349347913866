import { useQuery, useMutation } from '@apollo/client';
import { Dialog } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { FlavorType, ServerType } from '../../../__generated__/graphql';
import { INSTANCE_CREATE_MUTATION } from '../../../api/mutations/instances';
import { FLAVORS_QUERY } from '../../../api/queries/flavors';
import { PAGINATED_INSTANCES_QUERY } from '../../../api/queries/instances';
import { SERVERS_QUERY } from '../../../api/queries/servers';
import { INSTANCE_TYPE } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import {
  updateSelectTheme,
  updateSelectStylesOnError,
} from '../../../helpers/utils';
import { InfoTooltip, LoadingIndicator } from '../../../layout';
import { InfoAlert } from '../../../layout/alerts';
import { Input } from '../../../layout/fields';
import { setSuccessAlert } from '../../../redux/alertSlice';

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
function onKeyDown(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
  if ((keyEvent.code || keyEvent.key) === 'Enter') {
    keyEvent.preventDefault();
  }
}

interface FormikInitialStateType {
  server: SelectOption | undefined,
  flavor: SelectOption | undefined,
  mainDomain: string,
  siteName: string,
  aliasDomains: SelectOption[],
  redirectionDomains: SelectOption[],
}

interface Props {
  setOverlayState: (value: boolean) => void,
}

function CreateInstanceForm(props: Props) {
  const { setOverlayState } = props;

  const dispatch = useAppDispatch();
  const instancesQueryAttributesState = useAppSelector(
    (state) => state.instancesQueryAttributes,
  );

  const { data: flavorsData } = useQuery(FLAVORS_QUERY);
  const { data: serversData } = useQuery(SERVERS_QUERY);

  const [
    createInstanceMutation,
    { data: createInstanceData },
  ] = useMutation(
    INSTANCE_CREATE_MUTATION,
    {
      refetchQueries: [{
        query: PAGINATED_INSTANCES_QUERY,
        variables: {
          page: instancesQueryAttributesState.page,
          serverDomain: instancesQueryAttributesState.serverDomain,
          name: instancesQueryAttributesState.name,
          category: INSTANCE_TYPE.ACTIVE,
        },
      }],
    },
  );

  const schema = yup.object({
    server: yup.object().nullable().required('Requried'),
    flavor: yup.object().nullable().required('Requried'),
    mainDomain: yup.string().required('Requried'),
    siteName: yup.string().required('Requried'),
    aliasDomains: yup.array(),
    redirectionDomains: yup.array(),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    touched,
    values: formikValues,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      server: undefined,
      flavor: undefined,
      mainDomain: '',
      siteName: '',
      aliasDomains: [],
      redirectionDomains: [],
    } as FormikInitialStateType,
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        server: values?.server?.value,
        flavor: values?.flavor?.value,
        mainDomain: values.mainDomain,
        siteName: values.siteName,
        aliasDomainsList: values.aliasDomains.map((item) => item.value),
        redirectionDomainsList: values.redirectionDomains.map((item) => item.value),
      };
      createInstanceMutation({
        variables,
      });
    },
  });

  useEffect(() => {
    if (flavorsData) {
      const options = flavorsData.flavors.response.map((item: FlavorType) => ({
        value: item.id,
        label: item.name,
      }));
      setFieldValue('flavor', options[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flavorsData]);

  useEffect(() => {
    if (serversData) {
      const options = serversData.servers.map((item: ServerType) => ({
        value: item.id,
        label: item.domain,
      }));
      setFieldValue('server', options[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serversData]);

  useEffect(() => {
    if (
      createInstanceData
      && createInstanceData.instanceCreate
      && createInstanceData.instanceCreate.response
    ) {
      dispatch(setSuccessAlert({
        messages: [
          `Deployment of ${formikValues.siteName} has been started.`,
        ],
      }));
      setOverlayState(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInstanceData, formikValues, dispatch]);

  const currentServer = serversData
    && formikValues.server
    && serversData.servers.find((item: ServerType) => item.id === formikValues?.server?.value);

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={onKeyDown}
      className="flex h-full flex-col divide-y divide-slate-200  bg-white shadow-xl"
    >
      <div className="h-0 flex-1 overflow-y-auto">
        <div className="bg-slate-700 py-4 px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">
              New MODX Instance
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-slate-700 text-slate-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setOverlayState(false)}
              >
                <span className="sr-only">Close panel</span>
                <IconX className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-slate-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Instance Server
                  <span className="mr-1 text-red-500">*</span>
                </label>
                <div className="mt-1">
                  {serversData && serversData.servers.length ? (
                    <Select
                      value={formikValues.server}
                      name="server"
                      options={serversData.servers.map((item: ServerType) => ({
                        value: item.id,
                        label: item.domain,
                      }))}
                      isSearchable={false}
                      onChange={(e) => setFieldValue('server', e)}
                      theme={(theme) => updateSelectTheme(theme)}
                      //@ts-ignore
                      styles={formikErrors.server && updateSelectStylesOnError}
                    />
                  ) : (
                    <LoadingIndicator />
                  )}
                </div>
              </div>
              {formikValues.server && (
                <InfoAlert
                  message={`${currentServer.instancesLeft} Instance(s) more can be created on this server`}
                />
              )}
              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Instance Flavor
                  {' '}
                  <span className="mr-1 text-red-500">*</span>
                </label>
                <div className="mt-1">
                  {flavorsData && flavorsData.flavors.response.length ? (
                    <Select
                      value={formikValues.flavor}
                      name="flavor"
                      options={flavorsData.flavors.response.map((item: FlavorType) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      className="flavor-select"
                      classNamePrefix="select"
                      isSearchable={false}
                      onChange={(e) => setFieldValue('flavor', e)}
                      theme={(theme) => updateSelectTheme(theme)}
                      //@ts-ignore
                      styles={formikErrors.flavor && updateSelectStylesOnError}
                    />
                  ) : (
                    <LoadingIndicator />
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-bold text-slate-900">
                  Site Name
                  {' '}
                  <span className="mr-1 text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Input
                    placeholder="Example: Zeller & Co"
                    name="siteName"
                    value={formikValues.siteName}
                    //@ts-ignore
                    errorMessage={touched.siteName && formikErrors.siteName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label className="flex text-sm font-bold text-slate-900">
                  Main Domain
                  {' '}
                  <span className="mr-1 text-red-500">*</span>
                  {formikValues.server && (
                    <InfoTooltip
                      text={`Main Domain without a subdomain will automatically be extended with "${formikValues.server.label}" suffix`}
                    />
                  )}
                </label>
                <div className="mt-1">
                  <Input
                    placeholder="Example: zeller03.de"
                    name="mainDomain"
                    value={formikValues.mainDomain}
                    //@ts-ignore
                    errorMessage={touched.mainDomain && formikErrors.mainDomain}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-slate-900">
                  Alias Domains
                </label>
                <div className="mt-1">
                  <CreatableSelect
                    isMulti
                    isClearable
                    className="mt-1"
                    placeholder='Type in domain and press "Tab"'
                    onChange={(e) => (e
                      ? setFieldValue('aliasDomains', e)
                      : setFieldValue('aliasDomains', []))}
                    theme={(theme) => updateSelectTheme(theme)}
                    components={
                      {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                      }
                    }
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-slate-900">
                  Redirection Domains
                </label>
                <div className="mt-1">
                  <CreatableSelect
                    isMulti
                    isClearable
                    className="mt-1"
                    placeholder='Type in domain and press "Tab"'
                    onChange={(e) => (e
                      ? setFieldValue('redirectionDomains', e)
                      : setFieldValue('redirectionDomains', []))}
                    theme={(theme) => updateSelectTheme(theme)}
                    components={
                      {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        Menu: () => null,
                      }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md border border-slate-300 bg-white py-2 px-4 text-sm font-medium text-slate-700 shadow-sm hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
          onClick={() => setOverlayState(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2"
        >
          Deploy
        </button>
      </div>
    </form>
  );
}

export default CreateInstanceForm;
